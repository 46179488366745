import 'react-lazy-load-image-component/src/effects/opacity.css';
import 'intersection-observer';
import './styles/fonts';
import './styles/swiper.scss';

import { auth, database } from '@app/firebase';
import AsyncComponent from '@components/async/AsyncComponent';
import asyncRender from '@components/async/asyncRender';
import SupplierAgreementDialog from '@components/dialogs/SupplierAgreementDialog';
import Error from '@components/error/Error';
import Notification from '@components/notification/Notification';
import SplashAnimation from '@components/splash/SplashAnimation';
import UploadList from '@components/upload-list/UploadList';
import CONFIG from '@config';
import AppContext, { defaultAppContext } from '@context/AppContext';
import NotificationContext, { defaultNotificationContext } from '@context/NotificationContext';
import UploadContext from '@context/UploadContext';
import UserContext, { defaultUserContext } from '@context/UserContext';
import { CacheProvider } from '@emotion/react';
import useFirebaseEmailHandler from '@hooks/useFirebaseEmailHandler';
import useMaintenanceMode, { Maintenance } from '@hooks/useMaintenanceMode';
import useRouter from '@hooks/useRouter';
import Sentry from '@integrations/Sentry';
import FullCenter from '@layouts/full-center/FullCenter';
import { version as supplierTermsVersion } from '@legal/SupplierAgreement.md';
import { ThemeProvider } from '@mui/material/styles';
import routes from '@routes';
import { isLoginLandingPage } from '@routes';
import { AnalyticsBrowser } from '@segment/analytics-next';
import { ErrorBoundary, getCurrentScope } from '@sentry/react';
import theme from '@ui/theme';
import brandLogger from '@ui/utils/brandLogger';
import userFullName from '@ui/utils/userFullName';
import connectNewStreamUser from '@utils/connectNewStreamUser';
import { createMuiCache } from '@utils/createMuiCache';
import getUserData from '@utils/getUserData';
import { onAuthStateChanged,User as FirebaseUser } from 'firebase/auth';
import { collection, doc, getDoc, onSnapshot, QuerySnapshot, updateDoc } from 'firebase/firestore';
import cookie from 'js-cookie';
import React, { useEffect, useReducer, useState } from 'react';
import { createRoot } from 'react-dom/client';
import { pdfjs } from 'react-pdf';
import { BrowserRouter } from 'react-router-dom';
import { StreamChat } from 'stream-chat';
import { Chat } from 'stream-chat-react';

import createGlobalStyles from './main.style';

const client = StreamChat.getInstance(CONFIG.stream.api_key);

pdfjs.GlobalWorkerOptions.workerSrc = require('pdfjs-dist/build/pdf.worker.js')?.default;

brandLogger(GITHUB_REF, GITHUB_SHA);
declare global {
  interface Window {
    analytics: AnalyticsBrowser;
    GUESTHOUSE: guesthouse.Global;
    google_optimize?: {
      get(e: string): string
    };
  }
}

window.GUESTHOUSE = window.GUESTHOUSE || {};

// store a global var which is only set once the first auth state change is fired.
let appIsReady = false;
let animationComplete = false;

function useUnloadHandler(condition, handler) {
  useEffect(() => {
    if (condition) {
      window.addEventListener('beforeunload', handler);
    }
    return () => window.removeEventListener('beforeunload', handler);
  }, [condition, handler]);
}

function makeSaveds<T extends guesthouse.Identifiable>(snapshot: QuerySnapshot<T>): Saveds<T> {
  const ret: Saveds<T> = {
    list: [],
    ids: [],
  };

  if (snapshot.docs && snapshot.docs.length) {
    return snapshot.docs.reduce((acc, cur) => {
      const data = cur.data() as T;

      acc.list.push(data);
      acc.ids.push(data.docID);
      return acc;
    }, ret);
  }
}

const dispatchUploadContext = (
  state: { [key: string]: UploadState },
  action: UploadAction
) => {
  if (action.state === null) {
    const newState = { ...state };

    delete newState[action.key];
    return newState;
  }

  return {
    ...state,
    [action.key]: {
      ...state[action.key],
      ...action.state,
    },
  };
};

const Main = () => {
  const [uploadContext, setUploadContext] = useReducer(dispatchUploadContext, {});
  const [userContext, setUserContext] = useState<UserContext>(defaultUserContext);
  const [appContext, setAppContext] = useState<AppContext>(defaultAppContext);
  const [notificationContext, setNotificationContext] = useState<NotificationContext>(defaultNotificationContext);
  const [savedProducts, setSavedProducts] = useState<Saveds<guesthouse.Product>>();
  const [savedHomes, setSavedHomes] = useState<Saveds<guesthouse.Home>>();
  const [savedRooms, setSavedRooms] = useState<Saveds<guesthouse.Room>>();
  const [savedUsers, setSavedUsers] = useState<Saveds<guesthouse.User>>();
  const [supplierAgreementDialogOpen, setSupplierAgreementDialogOpen] = useState(false);

  const router = useRouter();

  useEffect(() => {
    window.analytics?.page();
  }, [router.location]);

  useFirebaseEmailHandler();

  useEffect(() => {
    setUserContext(userContext => ({ ...userContext, savedHomes, savedRooms, savedProducts, savedUsers }));
  }, [savedProducts, savedHomes, savedRooms, savedUsers]);

  useUnloadHandler(Object.keys(uploadContext).length, (e) => {
    e.preventDefault();
    const message = 'You have uploads in progress. Are you sure you want to leave?';

    e.returnValue = message;
    return message;
  });

  useEffect(() => {
    window.analytics?.addSourceMiddleware(({ payload, next }) => {
      const dnt = cookie.get(CONFIG.segment.dntCookie);

      if (dnt !== '1') {
        next(payload);
      }
    });
  }, []);

  useEffect(() => {
    const getClient = async (uid: string) => {
      const userDoc = await getDoc(doc(collection(database, 'users'), uid));

      if (userDoc?.exists()) {
        const user = userDoc.data() as guesthouse.User;

        try {
          const { streamUser } = await connectNewStreamUser(user);

          if (streamUser) {
            const userContextValsStream = { streamUser: streamUser };

            if (user.roles?.admin) {
              // @ts-ignore
              userContextValsStream.isAdminView = false;
            }
            setUserContext(userContext => ({ ...userContext, ...userContextValsStream }));
          }
        } catch (e) {
          Sentry.captureException(e);
        }
      }
    };

    if (userContext?.user?.uid && !userContext?.streamUser) {
      getClient(userContext.user.uid);
    }

  }, [userContext?.user?.uid, userContext?.streamUser]);

  useEffect(() => {
    if (!supplierAgreementDialogOpen && userContext.data && userContext.data.roles?.maker && userContext.data.supplierTermsVersion !== supplierTermsVersion) {
      const now = new Date();
      const userID = userContext.data.docID;

      asyncRender(SupplierAgreementDialog).then(() => {
        updateDoc(doc(collection(database, 'users'), userID), {
          supplierTermsVersion,
          supplierTermsAccepted: now,
        });
      });
      setSupplierAgreementDialogOpen(true);
    }
  }, [userContext.data]);

  useEffect(() => {
    let subscriptions = [];

    const handleAuthStateChange = async (user: FirebaseUser) => {
      if (user) {
        window.GUESTHOUSE.uid = user.uid;

        subscriptions.push(onSnapshot(
          doc(collection(database, 'users'), user.uid),
          async snapshot => {
            if (snapshot) {
              const data = snapshot.data() as guesthouse.User;

              let traits = {};

              if (data) {
                traits = {
                  name: userFullName(data),
                  email: data?.email,
                  roles: data?.roles,
                  flags: data?.flags
                };
              }

              const newContext: Partial<UserContext> = { data };

              window.analytics?.identify(user.uid, traits);

              // activeRegion should only be set in the database when the user has
              // more than one region. if the user only has one region, we should always
              // use that region
              if (data?.regions?.length === 1) {
                data.activeRegion = data.regions[0];
              }
              if (data?.activeRegion) {
                const newActiveRegion = await getDoc(doc(collection(database, 'regions'), data.activeRegion))
                  .then(doc => doc.data() as guesthouse.Region);

                newContext.activeRegion = newActiveRegion;
              } else {
                newContext.activeRegion = null;
              }

              setUserContext(state => ({ ...state, ...newContext }));
            }
          })
        );

        subscriptions.push(
          onSnapshot(
            collection(doc(collection(database, 'users'), user.uid), 'savedProducts'),
            snap => {
              const saveds = makeSaveds(snap as QuerySnapshot<guesthouse.Product>);

              setSavedProducts(saveds);
            })
        );

        subscriptions.push(
          onSnapshot(
            collection(doc(collection(database, 'users'), user.uid),'savedHomes'),
            snap => {
              const saveds = makeSaveds(snap as QuerySnapshot<guesthouse.Home>);

              setSavedHomes(saveds);
            })
        );

        subscriptions.push(
          onSnapshot(
            collection(doc(collection(database, 'users'), user.uid), 'savedRooms'),
            snap => {
              const saveds = makeSaveds(snap as QuerySnapshot<guesthouse.Room>);

              setSavedRooms(saveds);
            })
        );

        subscriptions.push(
          onSnapshot(collection(doc(collection(database, 'users'), user.uid), 'savedUsers'),
            snap => {
              const saveds = makeSaveds(snap as QuerySnapshot<guesthouse.User>);

              setSavedUsers(saveds);
            })
        );

        const { data, flags, regions, roles } = await getUserData(user);

        window.analytics?.track('login', {
          userId: user.uid,
          roles: roles
        });

        setUserContext(state => ({ ...state, data, flags, regions, roles, user }));

        const sentryScope = getCurrentScope();

        sentryScope.setUser({
          email: user.email,
          id: user.uid,
        });

        if (router.location.state?.next) {
          router.push(router.location.state.next);
        } else {
          router.push({
            pathname: isLoginLandingPage(router.location.pathname) ? routes.homes.path : router.location.pathname,
            search: router.location.search,
          });
        }

      } else {
        window.analytics?.reset();
        window.GUESTHOUSE.uid = undefined;

        setUserContext({ ...userContext, user });
        subscriptions.forEach(unsub => unsub());
        subscriptions = [];

        const sentryScope = Sentry.getCurrentScope();

        sentryScope.setUser(null);
      }

      setAppContext(state => ({ ...state, ready: true }));
      window.dispatchEvent(new Event('appready'));
      appIsReady = true;
    };

    return onAuthStateChanged(auth, handleAuthStateChange);
  }, []);

  const maintenanceMode = useMaintenanceMode();

  if (maintenanceMode) {
    return (
      <CacheProvider value={createMuiCache()}>
        <ThemeProvider theme={theme}>
          <Maintenance />
        </ThemeProvider>
      </CacheProvider>
    );
  }

  return (
    <ErrorBoundary
      fallback={({ error }) => {
        if (error?.message === 'not-found') {
          return (
            <Error
              statusCode="404"
              error={error}
            />
          );
        } else {
          return (
            <Error
              statusCode="500"
              error={error}
            />
          );
        }
      }}
    >
      <UserContext.Provider value={Object.assign(userContext, { setContext: setUserContext })}>
        <AppContext.Provider value={Object.assign(appContext, { setContext: setAppContext })}>
          <NotificationContext.Provider value={Object.assign(notificationContext, { setContext: setNotificationContext })}>
            <UploadContext.Provider value={{ uploads: uploadContext, setContext: setUploadContext }}>
              <Chat
                client={client}
                customStyles={{ '--primary-color': '#DDDDDD' }}
              >
                <CacheProvider value={createMuiCache()}>
                  <ThemeProvider theme={theme}>
                    <AsyncComponent
                      Loading={() => (
                        <FullCenter style={{ backgroundColor: '#ffffff', }}>
                          <SplashAnimation
                            style={{ maxWidth: 250, width: '100%' }}
                            onAnimationComplete={() => {
                              animationComplete = true;
                            }}
                          />
                        </FullCenter>
                      )}
                      Errored={() => (
                        <FullCenter>
                          <Error />
                        </FullCenter>
                      )}
                      resolve={() => {
                        return new Promise(resolve => {
                          return import('@app/App')
                            .then(module => {

                              if (document.hidden) {
                                resolve(module);
                                return;
                              }

                              if (window.location.search === '?noload') {
                                resolve(module);
                                return;
                              }

                              const wait = setInterval(() => {
                                if (appIsReady && animationComplete) {
                                  clearInterval(wait);
                                  resolve(module);
                                }
                              }, 100);
                            })
                            .catch((e) => {
                              Sentry.captureException(e);
                              resolve({
                                default: Maintenance,
                              });
                            });
                        });
                      }}
                      onError={Sentry.captureException}
                    />

                    <Notification />

                    <UploadList />
                  </ThemeProvider>
                </CacheProvider>
              </Chat>
            </UploadContext.Provider>
          </NotificationContext.Provider>
        </AppContext.Provider>
      </UserContext.Provider>
    </ErrorBoundary>
  );
};

document.head.appendChild(document.createElement('style')).textContent = createGlobalStyles(theme);
const container = document.createElement('div');

container.id = 'main';
document.body.appendChild(container);

const root = createRoot(container);

root.render(
  <BrowserRouter>
    <Main />
  </BrowserRouter>
);
